import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Headline from "../components/headline/headline.component"
import SEO from "../components/seo"
import Container from "../components/container/container.component"

import styles from "./about.module.scss"
import contactInfo from "../../site/settings/contact_info.json"
import myPhoto from "../../static/img/my-photo.jpg"
import "devicon"

const AboutPage = () => {
  const subText = `Little things about me. You could also download my resume <a href=${contactInfo.resume} target='_blank' rel='noopener noreferrer'>here</a>`
  return (
    <Layout>
      <SEO
        title="About me"
        description="Here are some information about myself"
      />
      <Headline mainText="About me" subText={subText} />
      <Container>
        <h3>Who am I?</h3>
        <img className={styles.myPhoto} src={myPhoto} alt="" />

        <p>
          Hey there! I am Viet, the creator of this website. Currently, I am
          a software developer working in Toronto,
          Canada. I started coding in 2016 and have spent
          most of my time on building web applications. I consider myself as a
          detail-oriented developer who loves spending time fixing little
          details and optimizing web apps. Also, I love working in a team
          because there is always something new and exciting to learn from other
          people.
        </p>
        <p>
          In my spare time, I like writing blogs, watching movies,
          and making lofi music.
        </p>

        <p>
          I created this website to showcase my personal projects and share my
          thoughts about tech & random things, hopefully you enjoy them. If you want to
          contact me, please ping me on my{" "}
          <a
            href={contactInfo.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>{" "}
          or fill in this <Link to="/contact">contact form.</Link>
        </p>

        {/* Languages */}
        <h3 style={{ clear: "left" }}>Languages I speak :)</h3>
        <ul className={styles.technologies}>
          <li>
            <i className="devicon-javascript-plain colored"></i>
          </li>
          <li>
            <i className="devicon-html5-plain-wordmark colored"></i>
          </li>
          <li>
            <i className="devicon-css3-plain-wordmark colored"></i>
          </li>
          <li>
            <i className="devicon-react-original-wordmark colored"></i>
          </li>
          <li>
            <i className="devicon-nodejs-plain-wordmark colored"></i>
          </li>
          <li>
            <i className="devicon-express-original-wordmark colored"></i>
          </li>
          <li>
            <i className="devicon-cplusplus-plain colored"></i>
          </li>
          <li>
            <i className="devicon-csharp-plain colored"></i>
          </li>
          <li>
            <i className="devicon-dot-net-plain-wordmark colored"></i>
          </li>
          <li>
            <i className="devicon-java-plain-wordmark colored"></i>
          </li>
          <li>
            <i className="devicon-oracle-original colored"></i>
          </li>
          <li>
            <i className="devicon-mongodb-plain-wordmark colored"></i>
          </li>
          <li>
            <i className="devicon-postgresql-plain-wordmark colored"></i>
          </li>
          <li>
            <i className="devicon-mysql-plain-wordmark colored"></i>
          </li>
        </ul>
      </Container>
    </Layout>
  )
}

export default AboutPage
